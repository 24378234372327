import { useAuth0 } from "@auth0/auth0-react";
import { saveState } from "../../state/utils/localStorage";
import React from "react";

const VerifyAccount = ({ title, description, continueTitle, lang }) => {
  const { loginWithRedirect } = useAuth0();

  const onContinueClick = () => {
    const href =
      lang === "en" ? "/complete-profile" : `/${lang}/complete-profile`;
    saveState("redirectUrl", href);
    loginWithRedirect({
      authorizationParams: {
        prompt: "none",
        redirect_uri:
          lang === "en"
            ? `${window.location.origin}/callback`
            : `${window.location.origin}/${lang}/callback`,
      },
      appState: {
        target: "redirectUrl",
      },
    });
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-center">
      <div className="tw-py-[70px] tw-px-[20px] tw-w-[500px] tw-flex tw-flex-col">
        <h1 className="tw-text-center">{title}</h1>
        <p className="tw-text-[14px] tw-leading-[24px] tw-font-sans tw-text-center tw-tracking-[0.2px]">
          {description}
        </p>
        <button
          onClick={onContinueClick}
          className="tw-bg-[#52c0e9] tw-cursor-pointer hover:tw-text-white tw-text-white tw-uppercase tw-py-3 tw-px-16 !tw-font-bold tw-text-xs tw-leading-4 tw-tracking-[0.5px] tw-self-center"
          style={{
            fontFamily: "Gotham",
          }}>
          {continueTitle}
        </button>
      </div>
    </div>
  );
};

export default VerifyAccount;
